import React from 'react';
import ReactApexChart from 'react-apexcharts';
import Alert from '../../Common/Alert';

const ComparisonGraph = (props) => {
  const { chartData, categories, selectedServices } = props;

  const chartOptions = (title) => ({
    title: {
      text: title,
      align: 'left',
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
        fontFamily: undefined,
        color: '#263238',
      },
    },
    chart: { type: 'bar', height: 300, stacked: selectedServices },
    xaxis: { categories },
    plotOptions: { bar: { horizontal: false } },
  });

  return (
    <>
      {chartData && chartData?.length > 0 ? (
        chartData?.map((data) => (
          <div
            key={data.key}
            className={
              chartData?.length === 4 || chartData?.length === 2
                ? 'col-md-6'
                : (chartData.length === 3
                  ? 'col-md-4'
                  : 'col-md-12')
            }
          >
            <ReactApexChart
              options={chartOptions(data.name)}
              series={selectedServices ? data?.data : [{ name: data?.name, data: data?.data }]}
              type="bar"
              width="100%"
              height={300}
            />
          </div>
        ))
      ) : (
        <div className="col-md-12">
          <Alert
            message="Please select at least 2 sub accounts"
            alertClass="info"
          />
        </div>
      )}
    </>
  );
};

export default ComparisonGraph;
