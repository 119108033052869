import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import Loader from '../../Loader';

const ServiceGraph = (props) => {
  const {
    category, products, range,
  } = props;
  const [loading] = useState(true);
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const convertToMonthYear = () => category.map((timestamp) => {
    // Extract year and month from the timestamp
    if (timestamp.length > 5) {
      const year = parseInt(timestamp.substring(3), 10);
      const month = parseInt(timestamp.substring(0, 2), 10);
      const monthName = months[month - 1]; // Adjust month index
      return { month, year, label: `${monthName}-${year}` };
    }
    const year = parseInt(timestamp.substring(2), 10);
    const month = parseInt(timestamp.substring(0, 1), 10);
    const monthName = months[month - 1]; // Adjust month index
    return { month, year, label: `${monthName}-${year}` };
  });

  const convertToOrdinal = (number) => {
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const remainder = number % 100;
    const suffix = suffixes[(remainder - 20) % 10] || suffixes[remainder] || suffixes[0];
    return number + suffix;
  };
  const convertToWeekMonth = () => {
    const dates = category.map((timestamp) => {
    // Extract year and month from the timestamp
      const week = parseInt(timestamp.substring(0, 1), 10);
      const month = parseInt(timestamp.substring(2), 10);
      const monthName = months[month - 1]; // Adjust month index
      const weekList = `${convertToOrdinal(week)} week - ${monthName}`;
      return weekList;
    });
    return dates;
  };

  const getMonthRange = () => {
    const convertedDates = convertToMonthYear();

    // Sort the convertedDates based on year and month
    convertedDates.sort((a, b) => {
      if (a.year !== b.year) {
        return a.year - b.year; // Sort by year first
      }
      return a.month - b.month; // If year is the same, sort by month
    });

    return convertedDates.map((date) => date.label);
  };

  const getCategories = (range) => {
    switch (range) {
      case 'year':
        return category;
      case 'month':
        return getMonthRange();
      case 'weekly':
        return convertToWeekMonth();
      case 'day':
        return category;
      default:
        return category;
    }
  };

  function kFormatter(num) {
    return Math.abs(num) > 999 ? `${Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1))}k` : Math.sign(num) * Math.abs(num);
  }

  const customLegendFormatter = (seriesName) => `
          <div class="legend-item-label">${seriesName}</div>
      `;

  const chartOptions = {
    noData: {
      text: 'Loading...',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: '#000000',
        fontSize: '20px',
        fontFamily: 'Helvetica',
      },
    },
    chart: {
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
      type: 'bar',
      height: 500,
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
      chart: {
        width: '100%',
      },
    },
    colors: ['#87CEEB', '#FFB6C1', '#FFFF00', '#cfb8d9', '#98FB98', '#d9cf52', '#6b6100'],
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 5,
        dataLabels: {
          enabled: false,
          total: {
            enabled: false,
            style: {
              fontSize: '11px',
              fontWeight: 900,
            },
          },
        },
      },
    },
    tooltip: {
      x: {
        formatter() {
          return 'Total(Cost)';
        },
      },
      y: {
        formatter(val) {
          return `$ ${val
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
        },
      },
    },
    xaxis: {
      categories: getCategories(range),
    },
    yaxis: {
      labels: {
        formatter(value) {
          return `$${kFormatter(value)}`;
        },
      },
    },
    legend: {
      position: 'bottom',
      fontSize: '10px',
      height: 200,
      formatter: customLegendFormatter,
    },
    itemMargin: {
      horizontal: 5,
      vertical: 0,
    },
    fill: {
      opacity: 1,
    },
  };

  return (
    <>
      {loading
        ? (
          <div className="card h-100">
            <div className="card-body">
              <h5>Services Usage</h5>
              <ReactApexChart
                options={chartOptions}
                series={products}
                type="bar"
                width="100%"
                height={500}
                className="service-usage-graph"
              />
            </div>
          </div>
        )
        : <Loader />}
    </>
  );
};

export default ServiceGraph;
