import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getModules } from '../../../Store/actions/modulesAction';
import { saveAndUpdateUserAccess, getUserAccessModule } from '../../../Store/actions/userAccessAction';
import Loader from '../../Loader';
import Alert from '../../Common/Alert';

const PermissionPopup = (props) => {
  const defaultModuleAccess = () => ({
    id: 0,
    employeeId: 0,
    application: '',
    moduleId: 0,
    moduleName: '',
    pageName: '',
    pageCode: '',
    readAccess: 0,
    writeAccess: 0,
  });
  const {
    employee, isOpen, onChange, hasAccess,
  } = props;
  const modalStyle = {
    display: `${isOpen ? 'block' : 'none'}`,
    paddingRight: '4px',
  };

  const dispatch = useDispatch();
  const moduleList = useSelector((state) => state.moduleList);
  const { modules, modulesLoading, errorModules } = moduleList;

  const employeeList = useSelector((state) => state.employeeList);
  const {
    userModulesAccess,
    errorUserModulesAccess,
    saveUserAccessResult,
    saveUserAccessResultError,
  } = employeeList;

  const [userModuleAccess, setUserModuleAccess] = useState([]);
  const [loader, setLoader] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showErrMsg, setShowErrMsg] = useState(false);

  useEffect(() => {
    const apiUrl = '/api/module/GetModules';
    dispatch(getModules(apiUrl));
  }, [employee]);

  useEffect(() => {
    if (modules?.length > 0) {
      const apiUrl = `/api/module/GetUserAccessModule/${employee.employeeId}`;
      dispatch(getUserAccessModule(apiUrl));
    }
  }, [modules]);

  useEffect(() => {
    if (userModulesAccess?.length > 0) {
      const modulesList = modules.map((item) => (
        {
          ...defaultModuleAccess,
          id: userModulesAccess.filter((e) => e.moduleId === item.id)[0]?.id,
          employeeId: employee.employeeId,
          application: item.application,
          moduleId: item.id,
          moduleName: item.moduleName,
          pageName: item.pageName,
          pageCode: item.pageCode,
          readAccess: userModulesAccess.filter((e) => e.moduleId === item.id)[0]?.readAccess,
          writeAccess: userModulesAccess.filter((e) => e.moduleId === item.id)[0]?.writeAccess,
        }
      ));
      setUserModuleAccess(modulesList);
    } else {
      const modulesList = modules.map((item) => (
        {
          ...defaultModuleAccess,
          employeeId: employee.employeeId,
          application: item.application,
          moduleId: item.id,
          moduleName: item.moduleName,
          pageName: item.pageName,
          pageCode: item.pageCode,
          readAccess: 0,
          writeAccess: 0,
        }
      ));
      setUserModuleAccess(modulesList);
    }
  }, [userModulesAccess]);

  function provideAccess(e, id, type) {
    const { target } = e;
    const updatedModules = userModuleAccess.map((el) => {
      if (type === 'read') {
        return el.moduleId === id ? { ...el, readAccess: target.checked } : el;
      } if (type === 'write') {
        return el.moduleId === id ? { ...el, writeAccess: target.checked } : el;
      }
      return el;
    });

    setUserModuleAccess(updatedModules);
  }
  function submit() {
    const data = userModuleAccess.filter((item) => item.id || item.readAccess || item.writeAccess);
    setLoader(true);
    const apiUrl = '/api/module/SaveAndUpdateEmployeeModuleAccess';
    dispatch(saveAndUpdateUserAccess(apiUrl, data));
  }

  useEffect(() => {
    if (saveUserAccessResult === 'success' && loader) {
      setLoader(false);
      setSuccessMessage(saveUserAccessResult);
      dispatch({ type: 'RESET' });
    }
  }, [saveUserAccessResult]);

  useEffect(() => {
    if (saveUserAccessResultError !== '' || errorModules !== '' || errorUserModulesAccess !== '') {
      setLoader(false);
      setShowErrMsg(true);
      dispatch({ type: 'RESET' });
    }
  }, [saveUserAccessResultError, errorModules, errorUserModulesAccess]);
  return (
    <>
      {loader
        ? <Loader />
        : (!modulesLoading ? (
          <div
            className="modal fade show"
            id="userAccessModal"
            tabIndex="-1"
            aria-labelledby="userAccessModalLabel"
            aria-hidden="true"
            style={modalStyle}
          >
            <div className="modal-dialog">
              <div className="modal-content">
                {successMessage === 'success'
                  ? (
                    <>
                      <div className="modal-header">
                        <h5 className="modal-title" id="newFeedbackModalLabel">Successfully Updated User Access</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { onChange(); setSuccessMessage(''); }} />
                      </div>
                      <div className="modal-footer">
                        <button type="button" className="btn btn-outline-primary" onClick={() => { onChange(); setSuccessMessage(''); }}>OK</button>
                      </div>
                    </>
                  )
                  : showErrMsg
                    ? (
                      <>
                        <div className="modal-body">
                          <div className="col-12">
                            <Alert
                              message="Something went wrong try again..."
                              alertClass="danger"
                              onClose={() => setShowErrMsg(false)}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="modal-header">
                          <h5 className="modal-title" id="userAccessModalLabel">
                            User Access for
                            {' '}
                            {employee.firstName}
                            {' '}
                            {employee.lastName}
                          </h5>
                          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { onChange(); }} />
                        </div>
                        <div className="modal-body">
                          <div className="table-responsive">
                            <table className="table table-bordered table-sm mb-0">
                              <thead className="table-light text-center">
                                <tr>
                                  <th>Application</th>
                                  <th>Module</th>
                                  <th>Page</th>
                                  <th>Read Access</th>
                                  <th>Write Access</th>
                                </tr>
                              </thead>
                              <tbody>
                                {userModuleAccess.map((item) => (
                                  <tr key={item.moduleId}>
                                    <td>
                                      {' '}
                                      {item.application}
                                    </td>
                                    <td>
                                      {' '}
                                      {item.moduleName}
                                    </td>
                                    <td>
                                      {' '}
                                      {item.pageName}
                                    </td>
                                    <td className="text-center">
                                      <input type="checkbox" checked={item.readAccess} className="form-check-input" onChange={(e) => provideAccess(e, item.moduleId, 'read')} />
                                    </td>
                                    <td className="text-center">
                                      <input type="checkbox" checked={item.writeAccess} className="form-check-input" onChange={(e) => provideAccess(e, item.moduleId, 'write')} />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="btn btn-primary" onClick={() => { onChange(); }}>Close</button>
                          {hasAccess
                            ? <button type="button" className="btn btn-primary" onClick={submit}>Save changes</button>
                            : ''}
                        </div>
                      </>
                    )}
              </div>
            </div>
          </div>
        ) : '')}
    </>
  );
};
export default PermissionPopup;
