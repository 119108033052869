import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import { faExclamationTriangle, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MUIDataTable from 'mui-datatables';
import { Context } from '../Context/MenuContext';
import PageHeader from '../Components/Common/PageHeader';
import CommonPopup from '../Components/Common/CommonPopup';
import ModulePopup from '../Components/NewModule/ModulePopup';
import { getModules, deleteModule } from '../Store/actions/modulesAction';
import Alert from '../Components/Common/Alert';
import Loader from '../Components/Loader';

const Modules = () => {
  const [tableData, setTableData] = useState([]);
  const [showError, setShowError] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteRow, setDeleteRow] = useState();
  const [popupType, setPopupType] = useState('');
  const [editRow, setEditRow] = useState([]);
  const [hasAccess, setHasAccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const [, , , setSelectedMenu, , setSelectedSubMenu] = useContext(Context);

  const dispatch = useDispatch();
  const moduleList = useSelector((state) => state.moduleList);
  const {
    modules, modulesLoading, errorModules, deleteStatus, errorDeleteModules, deleteModuleLoading,
  } = moduleList;

  const employeeList = useSelector((state) => state.employeeList);
  const {
    userAccess,
  } = employeeList;

  useEffect(() => {
    if ((userAccess?.length > 0
      && userAccess.filter((i) => i.pageCode === 'modules' && i.writeAccess)?.length > 0)) {
      setHasAccess(true);
    }
  }, []);

  useEffect(() => {
    const apiUrl = '/api/module/GetModules';
    dispatch(getModules(apiUrl));
    setLoading(true);
  }, []);

  const deleteSelectedModule = (moduleId) => {
    setShowDelete(false);
    const apiUrl = `/api/module/DeleteModule/${moduleId}`;
    dispatch(deleteModule(apiUrl));
  };

  useEffect(() => {
    setSelectedMenu('admin');
    setSelectedSubMenu('modules');
  }, []);

  useEffect(() => {
    if (modules.length > 0) {
      const s = modules.map((item) => (
        {
          moduleId: item.id,
          moduleName: item.moduleName,
          pageName: item.pageName,
          pageCode: item.pageCode,
          application: item.application,
        }
      ));
      setTableData(s.sort((a, b) => b.moduleId - a.moduleId).map(Object.values));
    } else {
      setTableData([]);
    }
  }, [modules, modulesLoading]);

  const handlePopupChange = () => {
    setOpenPopup(false);
    const apiUrl = '/api/module/GetModules';
    dispatch(getModules(apiUrl));
  };

  useEffect(() => {
    if (errorModules !== '') {
      setShowError(true);
    }
  }, [modulesLoading, modules, errorModules]);

  useEffect(() => {
    if (deleteStatus === 'success') {
      toast.success('Module deleted successfully!');
      const apiUrl = '/api/module/GetModules';
      dispatch(getModules(apiUrl));
    } else if (errorDeleteModules !== '') {
      toast.failure(errorDeleteModules);
    }
  }, [deleteStatus, errorDeleteModules, deleteModuleLoading]);

  function getColumn() {
    const columns = [
      {
        name: 'Id',
        options: {
          display: false,
          download: true,
          filter: false,
          print: false,
          viewColumns: false,
        },
      },
    ];
    columns.push({
      name: 'Module Name',
      options: {
        download: true,
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            position: 'sticky',
            background: '#f3f6f9',
            zIndex: 8,
          },
        }),
        setCellProps: () => ({
          style: {
            padding: '0 2rem',
          },
        }),
      },
    });
    columns.push({
      name: 'Page Name',
      options: {
        download: true,
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            position: 'sticky',
            background: '#f3f6f9',
            zIndex: 8,
          },
        }),
        setCellProps: () => ({
          style: {
            padding: '0 2rem',
          },
        }),
      },
    });
    columns.push({
      name: 'Page Code',
      options: {
        display: false,
        download: true,
        filter: false,
        print: false,
        viewColumns: false,
      },
    });
    columns.push({
      name: 'Application Name',
      options: {
        download: true,
        viewColumns: true,
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            position: 'sticky',
            background: '#f3f6f9',
            zIndex: 8,
          },
        }),
        setCellProps: () => ({
          style: {
            padding: '0 2rem',
          },
        }),
      },
    });
    columns.push({
      name: 'Action',
      options: {
        download: false,
        filter: false,
        viewColumns: true,
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            position: 'sticky',
            background: '#f3f6f9',
            zIndex: 8,
            textAlign: 'center',
          },
        }),
        customBodyRender: (dataIndex, rowIndex) => (
          <div className="text-center">
            <button
              type="button"
              className="btn text-primary me-1"
              onClick={() => {
                setEditRow(rowIndex.rowData); setOpenPopup(true);
                setPopupType('Edit');
              }}
            >
              <FontAwesomeIcon icon={faPen} />
            </button>
            <button type="button" className="btn text-danger" onClick={() => { setDeleteRow(rowIndex.rowData[0]); setShowDelete(true); }}>
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        ),
      },
    });
    return [...columns];
  }

  const options = {
    filter: true,
    fixedHeader: true,
    fixedSelectColumn: true,
    responsive: 'scroll',
    rowsPerPage: 5,
    expandableRows: false,
    expandableRowsHeader: false,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    print: false,
    filterType: 'dropdown',
    sortOrder: {
      name: 'UploadedDate',
      sort: true,
      direction: 'desc',
    },
  };
  return (
    <>
      <div className="wrapper">
        <div className="main">
          <PageHeader pageTitle="Modules">
            {
              hasAccess
                ? (
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => {
                      setOpenPopup(true);
                      setPopupType('Add');
                    }}
                  >
                    Add Module
                  </button>
                ) : ''
            }
          </PageHeader>
          <div className="page-content">
            <div className="card">
              <div className="card-body">
                {loading ? (
                  <div className="table-responsive">
                    {showError && (
                      <Alert
                        message={errorModules}
                        alertClass="danger"
                        onClose={() => setShowError(false)}
                      />
                    )}
                    <MUIDataTable
                      data={tableData}
                      columns={getColumn()}
                      options={options}
                    />
                  </div>
                ) : <Loader />}
              </div>
            </div>
          </div>
          {showDelete ? (
            <CommonPopup
              popupTitle="Delete Module"
              action={() => { deleteSelectedModule(deleteRow); }}
              close={() => setShowDelete(false)}
              btnValue="Yes"
              hasAccess={hasAccess}
              secBtn="Cancel"
            >
              <div className="d-flex align-items-center">
                <FontAwesomeIcon icon={faExclamationTriangle} className="fa-2x text-danger me-2" />
                <p className="mb-0">Are you sure you want to delete?</p>
              </div>
            </CommonPopup>
          ) : ''}
          {openPopup ? <ModulePopup onClose={handlePopupChange} type={popupType} row={editRow} hasAccess={hasAccess} /> : ''}
        </div>
      </div>
    </>
  );
};

export default Modules;
