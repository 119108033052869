import { subDays } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import axios from 'axios';
import './style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  getGraphData, getTrendingServiceData, getForecastingData, getTrendingServiceCumulativeData,
} from '../../../Store/actions/servicesAnalyticsAction';
import CsvDownload from '../../Common/CsvDownload';
import Loader from '../../Loader';
import CommonPopup from '../../Common/CommonPopup';
import ServiceComparison from '../ServiceComparison';

const ServiceFilter = (props) => {
  const {
    Type, onSelectServices, loading, services, Tab, onRangeSelect, onSelectAnalytics,
  } = props;
  const dispatch = useDispatch();
  const servicesAnalytics = useSelector((state) => state.servicesAnalytics);
  const { TrendingServiceData, TrendingServiceCumulativeData } = servicesAnalytics;

  const today = (subDays(new Date(), 1));
  const endDates = Tab === 'serviceUsage' ? subDays(today, 7) : subDays(today, 8);
  const defaultFilters = () => ({
    services: [],
    accounts: { value: '', label: 'All Accounts' },
    subAccounts: { value: '', label: 'All Sub Accounts' },
    viewId: { value: '324090', label: 'All Cloud' },
    startDate: endDates,
    endDate: today,
    pastMonths: { value: 6, label: 'Past 6 Month' },
    futureMonths: { value: 6, label: 'Future 6 Month' },
  });

  const [filters, setFilters] = useState(defaultFilters());
  const [chartInterval, setChartInterval] = useState({ value: 'day', label: 'Day' });
  const [analyticsTypes, setAnalyticsTypes] = useState({ value: 'overall', label: 'Overall Services' });
  const [chartRange, setChartRange] = useState({ value: 5, label: 'Top 5' });
  const [payerAccounts, setPayerAccounts] = useState(null);
  const [subAccounts, setSubAccounts] = useState(null);
  const [viewId, setViewId] = useState(null);
  const [csvData, setCsvData] = useState('');
  const [advanceFilters, SetAdvanceFilters] = useState(false);
  const [pastMonths, setPastMonths] = useState('');
  const [futureMonths, setFutureMonths] = useState('');
  const [showSubAccounts, setShowSubAccounts] = useState(false);
  const [showComparePopup, setShowComparePopup] = useState(false);
  const [compareModelTitle, setCompareModelTitle] = useState('');

  const [loader, setLoader] = useState(false);

  const analyticsTypesOption = [
    { value: 'overall', label: 'Overall Services' },
    { value: 'serviceWise', label: 'Service Wise' },
  ];

  const chartIntervalOptions = [
    { value: 'day', label: 'Day' },
    { value: 'weekly', label: 'Weekly' },
    { value: 'month', label: 'Month' },
    { value: 'quarterly', label: 'Quarterly' },
    { value: 'halfyearly', label: 'Half-Yearly' },
    { value: 'year', label: 'Year' },
  ];

  const chartRangeOptions = [
    { value: 0, label: 'Select Range' },
    { value: 5, label: 'Top 5' },
    { value: 10, label: 'Top 10' },
    { value: 15, label: 'Top 15' },
  ];

  function pastMonthOptions() {
    const months = [];
    for (let i = 6; i <= 9; i++) {
      months.push({ value: i, label: `Last ${i} Months` });
    }
    setPastMonths(months);
  }

  function futureMonthOptions() {
    const months = [];
    for (let i = 1; i <= 24; i++) {
      months.push({ value: i, label: `Future ${i} Months` });
    }
    setFutureMonths(months);
  }

  function getPayerAccounts() {
    const url = '/api/QueryAthena/GetPayerAccounts';
    axios
      .get(url)
      .then((response) => {
        if (response.data) {
          const val = response.data;
          val.push({ value: '', label: 'All Accounts' });
          if (Tab === 'serviceUsage') {
            setPayerAccounts(val);
          } else { setSubAccounts(val); }
        }
      })
      .catch((e) => {
        if (e.name !== 'AbortError') {
          console.log(e.message);
        }
      });
  }

  function getSubAccounts(payerAccount) {
    const url = `/api/QueryAthena/GetSubAccounts/${payerAccount}`;
    axios
      .get(url)
      .then((response) => {
        if (response.data) {
          const val = response.data;
          val.push({ value: '', label: 'All Sub Accounts' });
          setSubAccounts(val);
          setShowSubAccounts(true);
          setLoader(false);
        }
      })
      .catch((e) => {
        if (e.name !== 'AbortError') {
          console.log(e.message);
        }
      });
  }

  function getAllAccounts() {
    const url = '/api/QueryAthena/GetAllAccounts';
    axios
      .get(url)
      .then((response) => {
        if (response.data) {
          const val = response.data;
          val.push({ value: '324090', label: 'All Cloud' });
          setViewId(val);
        }
      })
      .catch((e) => {
        if (e.name !== 'AbortError') {
          console.log(e.message);
        }
      });
  }

  const dateFormatter = (date) => {
    const yyyy = date.getFullYear();
    let mm = date.getMonth() + 1; // Months start at 0!
    let dd = date.getDate();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;

    return `${yyyy}-${mm}-${dd}`;
  };

  function callAPI() {
    const payerId = filters.accounts.value;
    const vendorAcc = filters.subAccounts.value;
    const { startDate } = filters;
    const { endDate } = filters;
    const id = filters.viewId?.value !== undefined ? filters.viewId?.value : '';
    switch (Tab) {
      case 'serviceUsage':
        dispatch(getGraphData(`/api/QueryAthena/GetServiceAnalytics/${dateFormatter(startDate)}/${dateFormatter(endDate)}/${chartInterval.value}${payerId !== '' ? `/${payerId}` : ''}${vendorAcc !== '' ? `/${vendorAcc}` : ''}`));
        break;
      case 'forecastAnalytics':
        dispatch(getForecastingData(`/api/QueryAthena/GetForecastingData/${filters.pastMonths.value}/${filters.futureMonths.value}/${id}`));
        break;
      case 'serviceTrending':
        dispatch(getTrendingServiceData(`/api/QueryAthena/GetServiceTopTrendingData/${dateFormatter(startDate)}/${dateFormatter(endDate)}/serviceWise${payerId !== '' ? `/${payerId}` : ''}${vendorAcc !== '' ? `/${vendorAcc}` : ''}`));
        break;

      default:
        break;
    }
  }
  useEffect(() => {
    if (TrendingServiceData.length > 0 && TrendingServiceCumulativeData.length === 0) {
      const payerId = filters.accounts.value;
      const vendorAcc = filters.subAccounts.value;
      const { startDate } = filters;
      const { endDate } = filters;
      dispatch(getTrendingServiceCumulativeData(`/api/QueryAthena/GetServiceTopTrendingData/${dateFormatter(startDate)}/${dateFormatter(endDate)}/cumulative/?${payerId}/?${vendorAcc}`));
    }
  }, [TrendingServiceData]);

  const downloadCSV = () => {
    const payerId = filters.accounts.value;
    const { startDate } = filters;
    const { endDate } = filters;
    if (Tab === 'forecastAnalytics') {
      const id = filters.viewId?.value !== undefined ? filters.viewId?.value : '';
      axios
        .get(`/api/QueryAthena/GetForecastingData/${filters.pastMonths.value}/${filters.futureMonths.value}/${id}`)
        .then((response) => {
          if (response.data) {
            const result = response.data;
            result.forEach((innerArray) => {
              innerArray[0] = innerArray[0].replace(/,/g, '');
            });
            setCsvData(result);
          }
        })
        .catch((e) => {
          if (e.name !== 'AbortError') {
            console.log(e.message);
          }
        });
    } else {
      axios
        .get(`/api/QueryAthena/GetServiceAnalyticsCsv/${dateFormatter(startDate)}/${dateFormatter(endDate)}${payerId !== '' ? `/${payerId}` : ''}`)
        .then((response) => {
          if (response.data) {
            const result = response.data;
            result.forEach((innerArray) => {
              innerArray[0] = innerArray[0].replace(/,/g, '');
            });
            setCsvData(result);
          }
        })
        .catch((e) => {
          if (e.name !== 'AbortError') {
            console.log(e.message);
          }
        });
    }
  };

  useEffect(() => {
    switch (Tab) {
      case 'serviceUsage':
        setFilters(defaultFilters());
        setShowSubAccounts(false);
        getPayerAccounts();
        getAllAccounts();
        break;
      case 'forecastAnalytics':
        setFilters(defaultFilters());
        setShowSubAccounts(false);
        setChartRange({ value: 0, label: 'Select Range' });
        getAllAccounts();
        break;
      case 'serviceTrending':
        setFilters(defaultFilters());
        setShowSubAccounts(false);
        chartRangeOptions.push({ value: 'noRange', label: 'All Time Trending' });
        getPayerAccounts();
        break;

      default:
        break;
    }
    callAPI();
  }, [Tab]);

  useEffect(() => {
    if (Tab === 'forecastAnalytics') {
      if (filters.subAccounts.value !== '' && filters.subAccounts.label !== 'All Sub Accounts') {
        setFilters({
          ...filters,
          viewId: filters.subAccounts.value !== '' ? (viewId.find((item) => item.label === filters.subAccounts.label)) : '',
        });
      } else if (filters.accounts.label !== 'All Accounts') {
        setFilters({
          ...filters,
          viewId: filters.accounts.value !== '' ? (viewId.find((item) => item.label === filters.accounts.label)) : '',
        });
      } else {
        setFilters({
          ...filters,
          viewId: defaultFilters().viewId,
        });
      }
    }
  }, [filters.accounts, filters.subAccounts]);

  useEffect(() => {
    callAPI();
    downloadCSV();
    futureMonthOptions();
    pastMonthOptions();
    setCompareModelTitle(`Compare Services Data From ${dateFormatter(endDates)} To ${dateFormatter(today)}`);
  }, []);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#6c757d' : '#6c757d',
      fontSize: '0.8rem',
      fontWeight: '400',
      lineHeight: '1.5',
      backgroundColor: '#ecf0f1',
      backgroundClip: 'padding-box',
      border: '1px solid #ced4da',
      appearance: 'none',
      borderRadius: '0',
    }),
  };

  const handleAccountChange = (e) => {
    setFilters(
      {
        ...filters,
        accounts: e,
        subAccounts: defaultFilters().subAccounts,
      },
    );
    if (e.value !== '') {
      setLoader(true);
      getSubAccounts(e.value);
    } else {
      setShowSubAccounts(false);
    }
  };

  const handleSubAccountChange = (e) => {
    setFilters(
      {
        ...filters,
        subAccounts: e,
      },
    );
  };

  const handleServiceChange = (e) => {
    setFilters(
      {
        ...filters,
        services: e,
      },
    );
    onSelectServices(e?.map((i) => i.value));
  };

  const handlePastMonthChange = (e) => {
    setFilters(
      {
        ...filters,
        pastMonths: e,
      },
    );
  };

  const handleFutureMonthChange = (e) => {
    setFilters(
      {
        ...filters,
        futureMonths: e,
      },
    );
  };

  const handleDateChange = (data) => {
    setFilters({
      ...filters,
      startDate: data[0],
      endDate: data[1],
    });
  };

  const filterData = () => {
    loading();
    Type(chartInterval.value);
    setFilters(
      {
        ...filters,
        services: [],
      },
    );
    callAPI();
    downloadCSV();
  };

  return (
    <>
      {loader ? <Loader /> : ''}
      <div className="card h-100 mb-3">
        <div className="card-body">
          <h5>Service Usage Filters</h5>
          <div className="row">
            {/* Account Filter */}
            <div className="col-md-3 mb-3">
              <Select
                className="react-select"
                styles={customStyles}
                placeholder="Accounts"
                options={payerAccounts}
                onChange={(e) => { handleAccountChange(e); }}
                value={filters.accounts}
              />
            </div>
            {showSubAccounts && (
              <div className="col-md-3 mb-3">
                <Select
                  className="react-select"
                  styles={customStyles}
                  placeholder="Sub Accounts"
                  options={subAccounts}
                  onChange={(e) => { handleSubAccountChange(e); }}
                  value={filters.subAccounts}
                />
              </div>
            )}
            {Tab === 'forecastAnalytics' ? (
              <div className="col-md-3 mb-3">
                <Select
                  className="react-select"
                  name="pastMonths"
                  styles={customStyles}
                  placeholder="Past Months"
                  options={pastMonths}
                  onChange={(e) => handlePastMonthChange(e)}
                  value={filters.pastMonths}
                />
              </div>
            ) : ''}
            {/* Future Month Filter */}
            {Tab === 'forecastAnalytics' ? (
              <div className="col-md-3 mb-3">
                <Select
                  className="react-select"
                  name="futureMonths"
                  styles={customStyles}
                  placeholder="Future Months"
                  options={futureMonths}
                  onChange={(e) => handleFutureMonthChange(e)}
                  value={filters.futureMonths}
                />
              </div>
            ) : ''}
            {/* Analysis Type Filter */}
            {Tab === 'forecastAnalytics' && advanceFilters ? (
              <div className="col-md-3 mb-3">
                <Select
                  className="react-select"
                  styles={customStyles}
                  placeholder="Select Analysis Type"
                  options={analyticsTypesOption}
                  onChange={(e) => {
                    setAnalyticsTypes(e);
                    onSelectAnalytics(e.value);
                  }}
                  value={analyticsTypes}
                  defaultValue={analyticsTypes}
                />
              </div>
            ) : ''}
            {/* Service Filter */}
            {(Tab === 'serviceUsage') || (Tab === 'forecastAnalytics' && advanceFilters && analyticsTypes.value === 'serviceWise') ? (
              <div className="col-md-3 mb-3">
                <Select
                  className="react-select"
                  styles={customStyles}
                  placeholder="Services"
                  options={services}
                  onChange={handleServiceChange}
                  value={filters.services}
                  isMulti
                />
              </div>
            ) : ''}
            {(Tab === 'serviceUsage') || (Tab === 'serviceTrending') ? (
              <div className="col-md-3 mb-3">
                <ReactDatePicker
                  wrapperClassName="date-picker"
                  style={{ display: 'block' }}
                  selectsRange
                  placeholderText="Select a date range"
                  startDate={filters.startDate}
                  endDate={filters.endDate}
                  minDate={subDays(new Date(), (365 * 3))}
                  maxDate={new Date()}
                  onChange={handleDateChange}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
            ) : ''}
            {Tab === 'serviceUsage' ? (
              <div className="col-md-3 mb-3">
                <Select
                  className="react-select"
                  name="interval"
                  styles={customStyles}
                  placeholder="Chart Interval"
                  options={chartIntervalOptions}
                  onChange={(e) => setChartInterval(e)}
                  defaultValue={chartInterval}
                  value={chartInterval}
                />
              </div>
            ) : ''}
            {Tab === 'serviceTrending' || (Tab === 'forecastAnalytics' && advanceFilters && analyticsTypes.value === 'serviceWise') ? (
              <div className="col-md-3 mb-3">
                <Select
                  className="react-select"
                  name="interval"
                  styles={customStyles}
                  placeholder="Chart Range"
                  options={chartRangeOptions}
                  onChange={(e) => {
                    setChartRange(e);
                    onRangeSelect(e.value);
                  }}
                  defaultValue={chartRange}
                  value={chartRange}
                />
              </div>
            ) : ''}
            <div className="col-md-3 mb-3 filter-buttons">
              {/* Advanced Filter Toggle Button */}
              {Tab === 'forecastAnalytics' ? (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => SetAdvanceFilters(!advanceFilters)}
                >
                  {advanceFilters ? (
                    <FontAwesomeIcon icon={faTimes} />
                  ) : (
                    <FontAwesomeIcon icon={faFilter} />
                  )}
                </button>
              ) : ''}
              <button
                className="btn btn-primary"
                type="button"
                onClick={filterData}
              >
                Search
              </button>
              <CsvDownload csvData={csvData} fileName="ServiceAnalyticsData" />
              {'  '}
              {Tab === 'serviceUsage'
                && (
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={() => setShowComparePopup(true)}
                >
                  Compare
                </button>
                )}
            </div>
          </div>
        </div>
        {showComparePopup && (
          <CommonPopup
            popupTitle={compareModelTitle}
            btnValue="Compare"
            secBtn="Close"
            close={() => setShowComparePopup(false)}
            size="xl"
          >
            <ServiceComparison
              payerAccounts={payerAccounts}
              services={services}
              setCompareModelTitle={(e) => setCompareModelTitle(e)}
            />
          </CommonPopup>
        )}
      </div>
    </>
  );
};
export default React.memo(ServiceFilter);
