import { subDays } from 'date-fns';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import Loader from '../../Loader';
import ComparisonGraph from './comparisonGraph';

const ServiceComparison = (props) => {
  const {
    payerAccounts, services, setCompareModelTitle,
  } = props;

  const today = subDays(new Date(), 1);
  const endDates = subDays(today, 7);

  const defaultFilters = () => ({
    services: [],
    accounts: [],
    subAccounts: [],
    startDate: endDates,
    endDate: today,
    subAccountDDRKey: '',
  });

  const [filters, setFilters] = useState(defaultFilters());
  const [chartData, setChartData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subAccounts, setSubAccounts] = useState([]);
  const [loader, setLoader] = useState(false);
  const [showSubAccounts, setShowSubAccounts] = useState(false);
  const [error, setError] = useState({ accounts: '', subAccounts: '', dateFilter: '' });
  const [, setSelectedValues] = useState({});
  const [selectedServices, setSelectedService] = useState(false);
  const [callApi, setCallApi] = useState(false);
  const [payerAccountData, setPayerAccountData] = useState({});

  const dateFormatter = (date) => {
    const yyyy = date.getFullYear();
    let mm = date.getMonth() + 1; // Months start at 0!
    let dd = date.getDate();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;

    return `${yyyy}-${mm}-${dd}`;
  };

  function getDatesInRange(startDate, endDate) {
    const dates = [];

    const sDate = new Date(startDate);
    while (sDate <= endDate) {
      dates.push(sDate.toISOString().split('T')[0]); // Format as YYYY-MM-DD
      sDate.setDate(sDate.getDate() + 1); // Move to next day
    }

    return dates;
  }

  useEffect(() => {
    const fetchPayerAccountsData = async () => {
      if (!filters.accounts || filters.accounts.length === 0) return;

      setLoader(true);

      try {
        // Map over selected accounts to generate promises
        const promises = filters.accounts.map((account) => {
          const url = `/api/QueryAthena/GetServiceComparisionData/${account.value}/${dateFormatter(filters.startDate)}/${dateFormatter(filters.endDate)}`;
          return axios.get(url).then((response) => ({
            key: account.value,
            data: response.data || [],
          }));
        });

        // Wait for all promises to resolve
        const results = await Promise.all(promises);

        // Transform results into an object with payer account as the key
        const updatedData = results.reduce((acc, result) => {
          acc[result.key] = result.data.length > 0 ? result.data : null;
          return acc;
        }, {});
        setPayerAccountData((prevState) => ({
          ...prevState,
          ...updatedData,
        }));
      } catch (error) {
        console.error('Error fetching data for payer accounts:', error.message);
      } finally {
        setLoader(false);
      }
    };
    setCallApi(false);
    fetchPayerAccountsData();
  }, [filters.accounts, callApi]);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      fontSize: '0.8rem',
      backgroundColor: '#ecf0f1',
      border: '1px solid #ced4da',
    }),
  };

  const handleDateChange = (data) => {
    const [startDate, endDate] = data;

    // Check if the selected range exceeds 7 days
    if (startDate && endDate) {
      const diffInDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
      if (diffInDays > 7) {
        setError((prev) => ({ ...prev, dateFilter: 'You can select a date range of up to 7 days only.' }));
        // return;
      } else {
        setError((prev) => ({ ...prev, dateFilter: '' }));
      }
    }

    // Otherwise, update normally
    setFilters({
      ...filters,
      startDate,
      endDate,
    });
  };

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  };

  const getSubAccounts = async (payerAccount) => {
    const url = `/api/QueryAthena/GetSubAccounts/${payerAccount?.value}`;
    setLoader(true);
    try {
      const response = await axios.get(url);
      const val = response.data || [];
      val.push({ label: 'All Sub Accounts', value: '' });
      const subAcc = { key: payerAccount?.value, value: val, name: payerAccount?.label };
      setSubAccounts((prev) => [...prev, subAcc]);
      setShowSubAccounts(true);
    } catch (e) {
      console.error(e.message);
    } finally {
      setLoader(false);
    }
  };

  const handleAccountsChange = (selected) => {
    setFilters((prevAccState) => {
      const newAccState = { ...prevAccState, accounts: selected };

      const addedAccounts = selected?.filter(
        (newAccount) => !prevAccState.accounts?.some((account) => account.value === newAccount.value),
      );
      const removedAccounts = prevAccState.accounts?.filter(
        (prevAccount) => !selected?.some((account) => account.value === prevAccount?.value),
      );

      // handle remove payers account and respective subaccount
      if (removedAccounts?.length > 0) {
        const updatedSubAcc = subAccounts.filter((obj) => !removedAccounts.map((i) => i.value).includes(obj.key));
        setSubAccounts(updatedSubAcc);
      }
      if (addedAccounts?.length > 0) {
        getSubAccounts(addedAccounts[0]);
      }
      setChartData([]);
      setCategories([]);
      setError((prevError) => ({
        ...prevError,
        subAccounts: '',
      }));
      return newAccState;
    });
  };

  // this fucntion will get cost and date by subAccounts
  const fetchSubAccountData = (subAccount) => {
    const subAccountData = payerAccountData[subAccount.key].filter((record) => record[0] === subAccount.value)
      .map((record) => ({
        date: record[3],
        cost: record[4],
      }));
    return subAccountData;
  };

  const fetchSubAccountByServices = (subAccount, serviceName) => {
    const tempResult = {};
    payerAccountData[subAccount.key].forEach((record) => {
      const [resourceId, services, costs] = record;

      if (resourceId === subAccount.value) {
        const servicesArray = services
          .slice(1, -1) // Remove the square brackets
          .split(', ') // Split the string into an array by commas
          .map((service) => service.trim());

        const costArray = costs
          .slice(1, -1) // Remove the square brackets
          .split(', ') // Split the string into an array by commas
          .map((cost) => cost.trim());

        // If the service is the one we are interested in (e.g., 'AWS S3')
        if (servicesArray.includes(serviceName)) {
          const serviceIndex = servicesArray.indexOf(serviceName);
          const cost = costArray[serviceIndex];

          // Initialize result structure if not already
          if (!tempResult[resourceId]) {
            tempResult[resourceId] = [{ [serviceName]: [] }];
          }

          // Push the cost for the specific service
          tempResult[resourceId][0][serviceName].push(cost);
        }
      }
    });
    return tempResult;
  };

  const handleSubAccountsChange = (key, selected) => {
    const maxSum = 4;
    setSelectedValues((prevState) => {
      const newState = { ...prevState, [key]: selected === null ? [] : selected };
      // Check the sum of all selected values
      const sum = Object.values(newState)
        .map((option) => (option === null ? 0 : parseInt(option.length, 10)))
        .reduce((acc, val) => acc + val, 0);

      if (sum <= maxSum) {
        setFilters((prev) => (
          {
            ...prev,
            [key]: selected === null ? [] : selected,
            // subAccountDDRKey: key,
          }
        ));
        setError((oldErrState) => {
          const newErrState = { ...oldErrState };
          // Iterate through the keys and update if they start with the prefix
          Object.keys(newErrState).forEach((err) => {
            if (err.startsWith('subAccounts_')) {
              newErrState[err] = '';
            }
          });
          return newErrState;
        });
        return newState;
      }
      // If sum exceeds, do not update the state and display a message
      setChartData([]);
      setCategories([]);
      setError((prev) => ({ ...prev, [key]: 'You can select up to 4 sub-accounts only.' }));
      return prevState;
    });
  };

  useEffect(() => {
    try {
      // Collect selected subAccounts across all payer accounts
      const selectedSubAccounts = Object.keys(filters)
        .filter((key) => key.startsWith('subAccounts_'))
        .flatMap((key) => filters[key]); // Flatten the array of sub-accounts

      if (selectedSubAccounts.length < 2) {
        setChartData([]); // Clear chart data
        setError((prev) => ({
          ...prev,
          subAccounts: 'Please select at least two sub-accounts to display the graph.',
        }));
        return;
      }

      // Clear errors
      setError((prev) => ({ ...prev, subAccounts: '' }));

      // Check if services are selected
      if (filters.services?.length > 0) {
        const serviceNames = filters.services?.map((service) => service.label);

        // Limit to the first 4 sub-accounts and generate mock service data
        const limitedSubAccounts = selectedSubAccounts.slice(0, 4);

        const newChartData = limitedSubAccounts.map((subAccount) => {
          const mockServiceData = serviceNames.map((serviceName) => {
            const subAccDataByServices = fetchSubAccountByServices(subAccount, serviceName);
            return {
              name: serviceName,
              data: Object.hasOwn(subAccDataByServices, subAccount.value)
                ? subAccDataByServices[subAccount.value][0][serviceName] : [], // service.length > 0 ? service[0].data : [], // Use mock data or fallback to empty
            };
          });

          return {
            key: subAccount.value,
            name: subAccount.label,
            data: mockServiceData.map((item) => item), // Combine service data
          };
        });

        setChartData(newChartData);
        setSelectedService(true);
        return;
      }
      // If no services are selected, display mock data for sub-accounts
      const limitedSubAccounts = selectedSubAccounts.slice(0, 4);
      const newChartData = limitedSubAccounts.map((subAccount) => {
        // fetch subAccount data like Costs over the period selected by filter
        const subAccData = fetchSubAccountData(subAccount);
        return ({
          key: subAccount.value,
          name: subAccount.label,
          data: subAccData.map((i) => i.cost), // [123, 234, 345, 234, 556, 566], // Mock data
        });
      });

      const dateRange = getDatesInRange(filters.startDate, filters.endDate);
      setChartData(newChartData);
      setCategories(dateRange);
      setSelectedService(false);
    } catch (error) {
      console.error('Error on Account or Service Selection: ', error.message);
    }
  }, [filters]);

  const bindCompareData = () => {
    setCallApi(true);
    setCompareModelTitle(`Compare Services Data From ${dateFormatter(filters.startDate)} To ${dateFormatter(filters.endDate)}`);
  };

  return (
    <>
      {loader && <Loader />}
      <h5>Service Usage Filters</h5>
      <div className="row">
        {/* Payer Accounts Filter */}
        <div className="col-md-6 mb-3">
          <Select
            className="react-select"
            styles={customStyles}
            placeholder="Payer Accounts"
            options={payerAccounts}
            onChange={handleAccountsChange}
            value={filters.accounts}
            isMulti
          />
          {error.accounts && <small className="text-danger">{error.accounts}</small>}
        </div>

        {/* Service Multi Select */}
        <div className="col-md-3 mb-3">
          <Select
            className="react-select"
            styles={customStyles}
            placeholder="Services"
            options={services}
            onChange={(e) => handleFilterChange('services', e)}
            value={filters.services}
            isMulti
          />
        </div>

        {/* Date Picker */}
        <div className="col-md-3 mb-3">
          <ReactDatePicker
            wrapperClassName="date-picker"
            selectsRange
            placeholderText="Select a date range"
            startDate={filters.startDate}
            endDate={filters.endDate}
            minDate={subDays(new Date(), 365 * 3)}
            maxDate={new Date()}
            onChange={(e) => handleDateChange(e)}
            dateFormat="dd/MM/yyyy"
          />
          {error.dateFilter && <small className="text-danger">{error.dateFilter}</small>}
        </div>

        {/* Sub Accounts Filter */}
        {showSubAccounts
          && subAccounts?.map((item) => (
            <div key={item.key} className="col-md-3 mb-3">
              <label>{item.name}</label>
              <Select
                name={`subAccounts_${item.key}`}
                className="react-select"
                styles={customStyles}
                placeholder="Sub Accounts"
                options={item?.value}
                onChange={(e) => handleSubAccountsChange(`subAccounts_${item.key}`, e)}
                value={filters[`subAccounts_${item.key}`]}
                isMulti
              />
              {error[`subAccounts_${item.key}`] && (
                <small className="text-danger">{error[`subAccounts_${item.key}`]}</small>
              )}
            </div>
          ))}

        <div className="col-md-3 mb-3 d-flex ms-auto">
          <button
            className="btn btn-primary mt-auto ms-auto"
            type="button"
            onClick={bindCompareData}
          >
            Compare Data
          </button>
        </div>
      </div>

      <h5>Services Comparison Graphs</h5>
      <div className="row p-3">
        <ComparisonGraph
          chartData={chartData}
          categories={categories}
          selectedServices={selectedServices}
        />
      </div>
    </>
  );
};

export default React.memo(ServiceComparison);
