import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import toast from 'react-hot-toast';
import { uploadFile } from '../../../Store/actions/CostReportAction';
import Loader from '../../Loader';
import Alert from '../../Common/Alert';
import Select from '../../Common/Select';

const FileSubmitPopup = (props) => {
  const {
    onSubmit,
    onClose,
    screen,
  } = props;

  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [loader, setLoader] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [fileType, setFileType] = useState(null);

  const ValidateCSVData = async (file) => {
    const fileUrl = URL.createObjectURL(file);
    const response = await fetch(fileUrl);
    const text = await response.text();
    const exist = text.includes('$');
    return exist;
  };
  const SaveFile = async (event) => {
    const file = event.target.files[0];
    if (screen === 'awsAzure') {
      setSelectedFile(event.target.files[0]);
      setFileName(fileType);
    } else {
      const isValid = await ValidateCSVData(file);
      if (!isValid) {
        setSelectedFile(file);
        setFileName(file.name);
      } else {
        toast.error('Please varify uploaded data is valid');
      }
    }
  };

  const dispatch = useDispatch();
  const costReportResult = useSelector((state) => state.costReportResult);
  const { CostReportUploadMessage, CostReportUploadLoading, CostReportUploadMessageError } = costReportResult;
  const UploadFile = () => {
    const formData = new FormData();
    formData.append('formFile', selectedFile);
    formData.append('fileName', fileName);
    formData.append('module', screen);
    setLoader(true);
    const apiUrl = '/api/FileUpload/UploadFile';
    dispatch(uploadFile(apiUrl, formData));
  };
  useEffect(() => {
    if (CostReportUploadMessage === 'success' && loader) {
      setLoader(false);
      setShowSuccessMessage(true);
      setSuccessMessage('File successfully submitted');
      dispatch({ type: 'RESET' });
    }
  }, [CostReportUploadMessage]);
  useEffect(() => {
    if (!CostReportUploadLoading) {
      setLoader(false);
    }
  }, [CostReportUploadMessage, CostReportUploadMessageError]);

  useEffect(() => {
    if (CostReportUploadMessageError !== '') {
      setLoader(false);
      setShowErrorMessage(true);
    }
  }, [CostReportUploadMessageError, CostReportUploadMessage, CostReportUploadLoading]);

  const handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;
    if (name === 'ImageUpdate') {
      setFileType(value);
    }
  };

  return (

    <>
      {loader
        ? <Loader /> : (
          <>
            <div
              className="modal fade show"
              style={{ display: 'block' }}
              id="uploadFileModal"
              tabIndex="-1"
              aria-labelledby="uploadFileModalLabel"
              aria-hidden="true"
            >
              {showSuccessMessage ? (
                <>
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="successfullResponsePopup">
                          {successMessage}
                        </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onSubmit} />
                      </div>
                      <div className="modal-footer">
                        <button type="button" className="btn btn-outline-primary" onClick={onSubmit}>OK</button>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {CostReportUploadMessageError !== '' && showErrorMessage ? (
                    <Alert
                      message={CostReportUploadMessageError}
                      alertClass="danger"
                      onClose={() => setShowErrorMessage(false)}
                    />
                  ) : (
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          {screen === 'costreport' ? <h5 className="modal-title" id="uploadFileModalLabel">Upload Ascendon Cost Report</h5>
                            : <h5 className="modal-title" id="uploadFileModalLabel">Upload AWS/Azure Image </h5>}

                          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onClose} />
                        </div>
                        <div className="modal-body">
                          <div className={`${screen === 'costreport' ? 'd-none' : ''}`}>
                            <Select
                              name="ImageUpdate"
                              title="Choose file type"
                              value={fileType}
                              placeholder="Pick One..."
                              options={{ AWS: 'AWS', Azure: 'Azure' }}
                              onChange={handleChange}
                              required
                            />
                          </div>
                          <br />
                          <form>
                            <label className="file-upload">
                              <input type="file" onChange={SaveFile} id="fileUpload" accept={`${screen === 'awsAzure' ? 'image/*' : '.csv'}`} />
                              <p><FontAwesomeIcon icon={faCloudUploadAlt} style={{ fontSize: '50px' }} /></p>
                              <p className="lead mb-0">
                                Drag and drop or Browse file to upload
                              </p>
                            </label>
                          </form>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className={`btn btn-primary ${selectedFile === null || (fileType === null && screen === 'awsAzure') ? 'disabled' : ''}`} onClick={UploadFile}>Save changes</button>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
            <>

            </>

          </>
        )}
    </>

  );
};
export default FileSubmitPopup;
