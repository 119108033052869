import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle,
  faInfoCircle,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';

const Alert = (props) => {
  const { alertClass, message, onClose } = props;

  function displayIcon() {
    switch (alertClass) {
      case 'danger':
        return (
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            className="me-2"
          />
        );
      case 'info':
        return (
          <FontAwesomeIcon
            icon={faInfoCircle}
            className="me-2"
          />
        );
      case 'success':
        return (
          <FontAwesomeIcon
            icon={faCheckCircle}
            className="me-2"
          />
        );
      default:
        return null;
    }
  }
  return (
    <div className={`alert alert-${alertClass} alert-dismissible fade show`} role="alert">
      <div className="d-flex align-items-center">
        {displayIcon()}
        {message}
      </div>
      {onClose
        && (<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => onClose('')} />)}
    </div>
  );
};

export default Alert;
