import React, { useContext, useEffect, useState } from 'react';
import {
  faPen, faPlus, faTrash, faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toast from 'react-hot-toast';
import MUIDataTable from 'mui-datatables';
import { useDispatch, useSelector } from 'react-redux';
import { Context as MenuContext } from '../Context/MenuContext';
import Popup from '../Components/Admin/Announcement/Popup';
import PageHeader from '../Components/Common/PageHeader';
import { getAnnouncement, deleteSelectedAnnouncements } from '../Store/actions/announcementAction';
import CommonPopup from '../Components/Common/CommonPopup';
import Loader from '../Components/Loader';
import Alert from '../Components/Common/Alert';

const NewAnnouncement = () => {
  const [, , , setSelectedMenu, , setSelectedSubMenu] = useContext(MenuContext);
  const [hasAccess, setHasAccess] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [deleteRow, setDeleteRow] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const dispatch = useDispatch();
  const newAnnouncement = useSelector((state) => state.newAnnouncement);
  const {
    announcementList, announcementListError, announcementLoading, deleteAnnouncement, deleteAnnouncmentError, deleteLoading,
  } = newAnnouncement;

  const employeeList = useSelector((state) => state.employeeList);
  const {
    userAccess,
  } = employeeList;

  const [popupType, setPopupType] = useState('');
  const [editRow, setEditRow] = useState([]);
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSelectedSubMenu('newAnnouncement');
    setSelectedMenu('admin');
  }, []);

  useEffect(() => {
    if (announcementListError !== '') {
      setShowError(true);
    }
  }, [announcementListError]);

  const apiCall = () => {
    const apiUrl = '/api/announcement/GetAnnouncements';
    dispatch(getAnnouncement(apiUrl));
  };

  const handlePopupChange = () => {
    setOpenPopup(false);
    apiCall();
  };

  useEffect(() => {
    apiCall();
  }, []);

  useEffect(() => {
    if (announcementLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [announcementLoading]);
  useEffect(() => {
    if (userAccess?.length > 0
      && userAccess.filter((i) => i.pageCode === 'newAnnouncement' && i.writeAccess)?.length > 0) {
      setHasAccess(true);
    }
  }, []);

  const deleteAnnouncements = (Id) => {
    setShowDelete(false);
    const apiUrl = `/api/announcement/DeleteAnnouncement/${Id}`;
    dispatch(deleteSelectedAnnouncements(apiUrl));
  };

  useEffect(() => {
    if (deleteAnnouncement === 'success') {
      toast.success('Deleted Successfully');
    } else if (deleteAnnouncmentError !== '') {
      toast.error(deleteAnnouncmentError);
    }
    apiCall();
  }, [deleteLoading]);

  useEffect(() => {
    const s = announcementList.map((x) => (
      {
        Id: x.id,
        Title: x.header,
        Description: x.paragraph,
        Link: x.url,
        Link_Type: x.type,
        Status: x.status ? 'Activate' : 'Deactivate',
      }
    ));
    setTableData(s.map(Object.values));
  }, [announcementList]);

  function getColumn() {
    const columns = [
      {
        name: 'Id',
        options: {
          display: false,
          download: true,
          filter: false,
          print: false,
          viewColumns: false,
        },
      },
    ];
    columns.push({
      name: 'Title',
      options: {
        download: true,
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            position: 'sticky',
            background: '#f3f6f9',
            zIndex: 8,
          },
        }),
        setCellProps: () => ({
          style: {
            padding: '0 2rem',
          },
        }),
      },
    });
    columns.push({
      name: 'Description',
      options: {
        download: true,
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            position: 'sticky',
            background: '#f3f6f9',
            zIndex: 8,
          },
        }),
        setCellProps: () => ({
          style: {
            padding: '0 2rem',
          },
        }),
      },
    });
    columns.push({
      name: 'Link',
      options: {
        download: true,
        viewColumns: true,
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            position: 'sticky',
            background: '#f3f6f9',
            zIndex: 8,
          },
        }),
        setCellProps: () => ({
          style: {
            padding: '0 2rem',
          },
        }),
      },
    });
    columns.push({
      name: 'Link_Type',
      options: {
        download: true,
        viewColumns: true,
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            position: 'sticky',
            background: '#f3f6f9',
            zIndex: 8,
          },
        }),
        setCellProps: () => ({
          style: {
            padding: '0 2rem',
          },
        }),
      },
    });
    columns.push({
      name: ' Status',
      options: {
        download: true,
        viewColumns: true,
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            position: 'sticky',
            background: '#f3f6f9',
            zIndex: 8,
          },
        }),
        setCellProps: () => ({
          style: {
            padding: '0 2rem',
          },
        }),
      },
    });
    columns.push({
      name: 'Action',
      options: {
        download: false,
        filter: false,
        viewColumns: true,
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            position: 'sticky',
            background: '#f3f6f9',
            zIndex: 8,
            textAlign: 'center',
          },
        }),

        customBodyRender: (dataIndex, rowIndex) => (
          <div className="text-center">
            <button
              type="button"
              className="btn text-primary me-1"
              onClick={() => {
                setEditRow(rowIndex.rowData); setOpenPopup(true);
                setPopupType('Edit');
              }}
            >
              <FontAwesomeIcon icon={faPen} />
            </button>
            <button type="button" className="btn text-danger" onClick={() => { setDeleteRow(rowIndex.rowData[0]); setShowDelete(true); }}>
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        ),
      },
    });
    return [...columns];
  }

  const options = {
    filter: true,
    fixedHeader: true,
    fixedSelectColumn: true,
    responsive: 'scroll',
    rowsPerPage: 5,
    expandableRows: false,
    expandableRowsHeader: false,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    print: false,
    filterType: 'dropdown',
    sortOrder: {
      name: 'UploadedDate',
      sort: true,
      direction: 'desc',
    },
  };

  return (
    <>

      <div className="wrapper">
        <div className="main">
          <PageHeader pageTitle="Announcement Management">
            {hasAccess
              ? (
                <button
                  className="btn btn-primary"
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#uploadFileModal"
                  onClick={() => {
                    setOpenPopup(true);
                    setPopupType('Add');
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} />
                  {' '}
                  New Announcement
                </button>
              )
              : ''}
          </PageHeader>
          <div className="page-content">
            <div className="card">
              <div className="card-body">
                <>
                  {loading
                    ? (
                      <Loader />
                    ) : (
                      <>
                        {!showError ? (
                          <div className="table-responsive">
                            <MUIDataTable
                              columns={getColumn()}
                              options={options}
                              data={tableData}
                            />
                          </div>
                        ) : (
                          <Alert
                            message={announcementListError}
                            alertClass="danger"
                            onClose={() => setShowError(false)}
                          />
                        )}
                      </>
                    ) }
                </>
              </div>
            </div>
          </div>
          {openPopup ? (
            <Popup hasAccess={hasAccess} type={popupType} row={editRow} onClose={handlePopupChange} apiCall={apiCall} />
          ) : ''}
          {showDelete ? (
            <CommonPopup
              popupTitle="Delete Module"
              action={() => { deleteAnnouncements(deleteRow); }}
              close={() => setShowDelete(false)}
              btnValue="Yes"
              hasAccess={hasAccess}
              secBtn="Cancel"
            >
              <div className="d-flex align-items-center">
                <FontAwesomeIcon icon={faExclamationTriangle} className="fa-2x text-danger me-2" />
                <p className="mb-0">Are you sure you want to delete?</p>
              </div>
            </CommonPopup>
          ) : ''}
        </div>
      </div>

    </>
  );
};

export default NewAnnouncement;
